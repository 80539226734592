
<template>
  <div>
    <v-dialog v-model="dialog" scrollable persistent>
      <v-card style="border-radius:5px">

        <v-card-title primary-title class='primary white--text'>
        <span class="text-subtitle">Formulario de avaliação</span>
        </v-card-title>

        <card-loading-transparent v-if="loading_dialog" class="pt-4"></card-loading-transparent>
        <v-card-text v-if="!loading_dialog">

          <v-list subheader v-for="item in form_items" :key="item.id" class="py-0">
            <v-list-item class="py-0">
              <v-list-item-content class="py-0">
                <v-list-item-subtitle class="text-wrap" v-if="item.answer">{{item.title}}</v-list-item-subtitle>
                <v-list-item-title class="text-wrap" v-if="!item.answer"><span class="text-h6">{{item.title}}</span></v-list-item-title>
                <v-list-item-title class="text-wrap mb-5" v-if="item.answer != 'null'">
                  <span v-if="item.item_kind != 40" >{{item.answer}}</span>
                  <v-rating
                    empty-icon="$ratingFull"
                    length="6"
                    hover
                    readonly
                    dense
                    size="32"
                    :value="parseInt(item.answer)"
                    v-if="item.item_kind == 40"
                  >
                  <template v-slot:item="item">
                    <v-icon :color="item.isFilled ? 'primary' : 'grey lighten-2' ">
                      {{ item.isFilled ? 'mdi-star' : 'mdi-star-outline' }}
                    </v-icon>
                  </template>

                  </v-rating>
                </v-list-item-title>

                <v-list-item-subtitle class="text-wrap">
                  <v-btn v-if="item.file_url" :disabled="item.file_url == null" class="" small color="primary" dark @click="dialog_file = true"><v-icon left>mdi-file</v-icon>Abrir comprovante</v-btn>
                </v-list-item-subtitle>
                <dialog-show-file v-if="item.file_url" title="Comprovante de embarque" :file_url="item.file_url" :file_kind="item.file_kind" :dialog_file="dialog_file" @closeDialog="dialog_file = false"></dialog-show-file>
                <v-divider v-if="!item.answer" class="mt-n8"></v-divider>
              </v-list-item-content>
            </v-list-item>
          </v-list>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="closeDialog"><v-icon left>mdi-close</v-icon>Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Api from '@/api/index'

export default {
  name: 'DialogRatingDoneForm',
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    selected_done_form: {
      type: Object,
      default: ()=> {},
    },
  },
  data() {
    return {
      dialog_file: false,
      form_items: [],
      loading_dialog: true,
      one_done_form: {}
    }
  },
  methods: {
    closeDialog(){
      this.$emit('closeDialog')
    },
    async loadDialog(){
      this.loading_dialog = true
      this.one_done_form = this.selected_done_form
      //this.one_done_form = await this.getDoneForm()
      if(this.one_done_form.id){
        this.form_items = await this.getDoneItems(this.one_done_form.id)
      }
      setTimeout(() => {
        this.loading_dialog = false
      }, 500);
    },
    async getDoneItems(){
      return await Api.DoneItems.indexByDoneForm(this.selected_done_form.id).then(r=>r.data).then(d=>{
        return d
      })
    },
    ...mapActions({
      // function: 'Module/action'
    })
  },
  computed: {
    ...mapState({
      // data: state=> state.Module.state_var
    })
  },
  mounted () {
    this.loadDialog();
  },
}
</script>

<style scoped>

</style>